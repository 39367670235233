<template>
  <div class="container">
    <!-- 按钮列表 -->
    <div
      class="btnList1"
      ref="btnList"
    >
      <el-button
        class="itemBtn btnColor"
        @click="itemClick(1)"
      >新建</el-button>
      <el-button
        class="itemBtn btnColor"
        @click="itemClick(2)"
      >修改</el-button>
      <el-button
        class="itemBtn btnColor"
        @click="delBtns"
      >删除</el-button>
      <el-button
        class="itemBtn btnColor"
        @click="EnableBut"
      >导入</el-button>
    </div>
    <!-- 表格 -->
    <div
      class="tableBox"
      ref="tableWrapper"
      :class="{ tableBox: TableStyle, tableBox1: fullscreen }"
    >
      <el-table
        :height="tableHeigth"
        ref="mytable"
        :data="tableData"
        :row-class-name="tableRowClassName"
        highlight-current-row
        :header-cell-style="{ background: '#f0f0f0' }"
        border
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          fixed="left"
          label="序号"
          type="index"
          width="60"
        >
        </el-table-column>
        <el-table-column
          fixed="left"
          type="selection"
          width="60"
        >
        </el-table-column>
        <template v-for="(item, index) in tabelHeadeTitle">
          <el-table-column
            :key="index"
            :label="item.name"
            :prop="item.field"
            sortable
            :min-width="item.width"
            :data-isTotal="item.isTotal"
            v-if="item && item.isShow"
          >
          </el-table-column>
        </template>
      </el-table>
    </div>
    <!-- 分页组件 -->
    <paging
      ref="pags"
      :pageNum="pageNum"
      :total="total"
      :sizeList="sizeList"
      :size="size"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    ></paging>

    <!-- 弹窗 -->
    <myDialog
      :diaWidth="diaWidth"
      :dialogTitle="PopUpTitle"
      :isShowDialog="menushowBOX"
      @handleCloseDia="handleCloseDia"
      @diaCancelBtn="diaCancelBtn"
      @diaConfirmBtn="diaConfirmBtn('dialogFormDataRef')"
      :dialogFooter="dialogType != 3"
    >
      <div class="glo_dialogForm">
        <el-form
          class="diaForm"
          v-if="menushowBOX"
          ref="dialogFormDataRef"
          :model="dialogFormData"
          label-width="100px"
        >
          <el-form-item
            class="dialogFormItems"
            label="开始日期:"
            style="overflow: hidden;"
          >
            <el-date-picker
              class="input"
              value-format="yyyy-MM-dd"
              v-model="dialogFormData.belongDate"
              type="date"
              placeholder="选择日期"
              :disabled="dialogType == 2"
            ></el-date-picker>
          </el-form-item>
          <el-form-item
            class="dislogFormTextarea"
            label="节点列表:"
          >
            <div
              class="nodlist"
              v-for="(item, index) in dialogFormData.nodes"
            >
              <span>{{ item.nodeName }} :</span>
              <el-date-picker
                class="input"
                value-format="yyyy-MM-dd"
                v-model="item.nodeDate"
                type="date"
                placeholder="选择日期"
              ></el-date-picker>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </myDialog>

    <!-- 导入报价分区  弹窗 -->
    <el-dialog
      title="导入"
      append-to-body
      :visible.sync="showDaoRuFenQu"
      @close="closeshowDaoRuFenQu"
      width="60%"
      center
    >
      <div>
        <div>
          提示:
          从Excel中直接拷贝,两个数字自己采用Tab键进行分割,两行之间采用分行进行分割
        </div>
        <div class="titHead">
          <div class="titItem">日期</div>
          <div
            class="titItem"
            v-for="(item, index) in nodes"
          >
            {{ item.nodeName }}
          </div>
        </div>
        <el-input
          v-model="daoRuVal"
          type="textarea"
          placeholder="请粘贴内容"
          rows="20"
        ></el-input>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="showDiaDaoRu_save"
        >确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { apiList, Api } from '@/assets/js/api'
import tools from '@/assets/js/tool'
import myDialog from '@/components/Dialog.vue'
import paging from '@/components/pagings.vue'

export default {
  inject: ['reload'],
  components: {
    paging,
    myDialog,
  },
  data() {
    return {
      PopUpTitle: '', //弹窗标题
      menushowBOX: false, //菜单弹窗
      dialogType: '',
      menuTableData: [],
      diaWidth: '60%',
      dialogFormData: {
        nodes: [],
        belongDate: '',
      },
      judge: true, //输入框绑定动态class
      fullscreen: false,
      TableStyle: true,
      tableHeigth: '50vh',
      tabelHeadeTitle: [],
      tableData: [],
      selectTableData: [], //选中的数据
      pageNum: 1, //页码
      total: 0, //总条数
      sizeList: [10, 20, 50, 100, 200],
      size: 50, //一页几条
      calendarActionId: '',
      nodes: [],
      showDaoRuFenQu: false,
      daoRuVal: '',
      piLiangFenQe: [], //批量导入分区
      nodesSet: [],
    }
  },
  updated() {},
  created() {},
  mounted() {},
  activated() {
    this.calendarActionId = this.$route.query.calendarActionId || ''
    this.getData()
  },
  watch: {
    // 导入的报价
    daoRuVal: function (newVal, old) {
      // console.log(newVal)
      // console.log('导入的数据')
      var String = []
      String.push(newVal)

      let datsArr = String[0].split('\n') || []
      if (datsArr.length <= 0) {
        return
      }

      let flag = /\s/ //所有空白字符
      let newArr = [] //切割后的数组
      for (var i = 0; i < datsArr.length; i++) {
        if (datsArr[i]) {
          let strArr = datsArr[i].split(flag)
          let arr1 = []
          for (var z = 0; z < strArr.length; z++) {
            if (strArr[z]) {
              arr1.push(strArr[z])
            }
          }
          newArr.push(arr1)
        }
      }
      if (newArr.length == 0) {
        return
      }

      let arr1 = []
      let list = []
      newArr.forEach((element, index) => {
        let als = []
        element.forEach((n, i) => {
          als.push({
            fieldName: this.nodesSet[i].fieldAlias,
            fieldShow: this.nodesSet[i].fieldNameShow,
            fieldVal: n,
          })
        })
        list.push(als)
      })
      this.piLiangFenQe = list
    },
  },
  methods: {
    closeshowDaoRuFenQu() {
      this.daoRuVal = ''
    },
    //批量保存分区
    showDiaDaoRu_save() {
      let param = {
        calendarActionId: this.calendarActionId,
        datas: this.piLiangFenQe || [], //分区信息
      }
      Api.importDates(param).then((res) => {
        if (res.data.status == 'success') {
          this.$message.success(res.data.message || '导入成功')
          this.showDaoRuFenQu = false
          this.getrunReport()
        }
      })
    },
    //分页
    handleSizeChange(val) {
      this.size = val
      this.getrunReport()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getrunReport()
    },
    // 删除
    delBtns() {
      if (this.selectTableData.length < 1) {
        this.$message.warning('请至少选择一条数据进行删除')
        return false
      }
      let ids = []
      this.selectTableData.forEach((item) => {
        ids.push(item.belongDate)
      })
      this.$confirm('是否删除选中的数据?', '提示:')
        .then(() => {
          let param = {
            calendarActionId: this.calendarActionId,
            belongDates: ids,
          }
          param.sign = tools.getSign(param)
          Api.delNodeDate(param).then((res) => {
            this.$message.success(res.data.message || '删除成功')
            this.getrunReport()
          })
        })
        .catch(() => {})
    },
    EnableBut() {
      this.showDaoRuFenQu = true
      this.piLiangFenQe = []
      this.daoRuVal = ''
    },
    async itemClick(type) {
      this.dialogType = type
      let nodes = JSON.parse(JSON.stringify(this.nodes))
      nodes.forEach((n) => {
        n.nodeDate = ''
      })
      this.dialogFormData.nodes = nodes
      if (type == 1) {
        this.PopUpTitle = '新建'
        this.menushowBOX = true
      } else if (type == 2) {
        this.PopUpTitle = '修改'
        let selectTableData = this.selectTableData || []
        if (selectTableData.length != 1) {
          this.$message.warning('请勾选一条数据')
          return
        }
        await Api.getNodeDateDetail({
          belongDate: this.selectTableData[0].belongDate,
          calendarActionId: this.calendarActionId,
        }).then((res) => {
          this.dialogFormData = res.data.result || {}
          this.menushowBOX = true
        })
      }
    },
    /***
     * ====== 弹窗组件方法 ======
     */
    //  弹窗关闭事件(左上角的x和关闭按钮都算)
    handleCloseDia() {
      this.menushowBOX = false
      this.dialogFormData = {
        nodes: [],
        belongDate: '',
      }
    },
    //  弹窗确认保存事件
    diaConfirmBtn(formName) {
      this.dialogFormData.calendarActionId = this.calendarActionId
      if (this.dialogType == 1) {
        // 新建
      } else if (this.dialogType == 2) {
        // 修改
      }
      this.dialogFormData.sign = tools.getSign(this.dialogFormData)
      Api.addNodeDate(this.dialogFormData).then((res) => {
        this.$message.success(res.data.message || '新建成功')
        this.menushowBOX = false
        tools.resetForm(this, formName)
        this.getrunReport()
      })
    },
    //  关闭弹窗按钮事件(关闭按钮)
    diaCancelBtn() {
      this.menushowBOX = false
    },
    //表格样式i
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return 'warning-row'
      }
    },
    // 表格选择事件
    handleSelectionChange(e) {
      this.selectTableData = e
    },
    /**
     * ******************************计算表格高度**开始*************************
     */
    // 重置table高度
    resetHeight() {
      return new Promise((resolve, reject) => {
        this.tableHeigth = 0
        resolve()
      })
    },
    // 设置table高度
    fetTableHeight() {
      this.resetHeight().then((res) => {
        this.tableHeigth = this.getHeight()
        //console.log(this.tableHeigth + "表格高度");
        this.$nextTick(() => {
          this.$refs.mytable.doLayout()
        })
      })
    },
    // 获取表格高度
    getHeight() {
      let windonHeight = document.body.clientHeight
      let queryHeigth
      let btnListHeight = this.$refs.btnList.offsetHeight + 16 // 按钮列表  16==上下的margin
      if (this.judge == true) {
        queryHeigth = 0 //查询框
      } else {
        queryHeigth = 50
      }
      let pagsHeigth = 40 //分页组件
      var otherHeight = queryHeigth + btnListHeight + pagsHeigth + 60 + 10 //60是顶部导航 ,10==预留空位
      return Math.floor(windonHeight - otherHeight) || '50vh' //
    },
    /**
     * ******************************计算表格高度**结束*************************
     */
    // 获取数据
    async getData() {
      Api.getAddFields({
        calendarActionId: this.calendarActionId,
      }).then((res) => {
        if (res.data.status == 'success') {
          this.nodes = res.data.result.nodes || []
        }
      })
      //
      await this.$API
        .getReportFiledsV2({
          conditionReqs: [
            {
              conditonKey: 'calendarActionId',
              conditonVal: this.calendarActionId,
            },
          ],
          number: 'calentdar_action_date_list',
        })
        .then((res) => {
          if (res.data.result) {
            let results = res.data.result || []
            this.nodesSet = results
            let tabelHeadeTitle = []
            results.map((n) => {
              tabelHeadeTitle.push({
                name: n.fieldNameShow,
                field: n.fieldAlias,
                width: n.wide,
                sort: n.sort,
                isSort: true,
                isShow: n.isShow == 1 ? true : false,
                isTotal: n.isSum == 1 ? true : false,
              })
            })
            this.tabelHeadeTitle = tabelHeadeTitle
          }
        })
      this.getrunReport()
    },
    // 获取数据
    getrunReport() {
      let param = {
        conditionReqs: [
          {
            conditonKey: 'calendarActionId',
            conditonVal: this.calendarActionId,
          },
        ],
        number: 'calentdar_action_date_list',
        pageStart: this.pageNum,
        pageTotal: this.size,
      }
      this.$API.runPageReport(param).then((res) => {
        if (res.data.result) {
          let results = res.data.result.datas || []
          this.tableData = results.data || []
          this.total = results.pageCount || 0
          this.fetTableHeight()
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.tableBox {
  height: calc(100% - 260px);
}
.nodlist {
  display: flex;
}
.titHead {
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 6px;
  font-size: 16px;

  .titItem {
    margin-right: 50px;
    margin-left: 20px;
    font-weight: bold;
  }
}
</style>
